import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import SEO from "../../components/SEO"
import SkuTile from "../../components/SkuTile"
import Image from "../../components/Image"

import styles from "./story.module.scss"

const FW21 = ({ data, location }) => {
  const {
    nodes
  } = data.allProductStyle

  const [allSkus, setAllSkus] = useState([])

  useEffect(() => {
    let reorderedNodes = [
      "perfecto-vitello-boots",
      "buckle-up-nappa-boots",
      "fuel-vitello-boots",
    ].map(handle => nodes.find(style => style.handle === handle))

    let updatedSkus = []
    reorderedNodes.forEach(style => {
      style.skus.forEach(sku => {
        if (sku.images.plp.length > 0 || sku.images.plp2.length > 0)
          updatedSkus.push(sku)
      })
    })
    setAllSkus(updatedSkus)
  }, [nodes])

  return (
    <>
      <SEO
        title="Moto"
        description="Fall Winter '21"
        url={location.pathname}
      />

      <ul className={styles.grid}>
        {allSkus.map((sku, i) => (
          <SkuTile
            key={i}
            index={i}
            style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
            sku={sku}
            customStyles={styles}
            origin={`story-moto`}
          />
        ))}
        <li className={styles.storyItem41}>
          <Image
            image={{
              desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Story_Moto_1320x.jpg?v=1628873529",
              mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Story_Moto_850x.jpg?v=1628873529",
            }}
            altText="Motocross Story"
          />
        </li>
      </ul>

      <div className={styles.videoModule}>
        {typeof window !== "undefined" && window.outerWidth < 768 &&
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video autoPlay loop muted playsInline className={styles.video}>
          <source
            src="https://cdn.shopify.com/s/files/1/1103/4464/files/MOBILE_MotoPage.mp4?v=1630014906"
            type="video/mp4" />
        </video>
        }
        {typeof window !== "undefined" && window.outerWidth >= 768 &&
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video autoPlay loop muted playsInline className={styles.video}>
          <source
            src="https://cdn.shopify.com/s/files/1/1103/4464/files/DESKTOP_MotoPage.mp4?v=1630014906"
            type="video/mp4" />
        </video>
        }
      </div>
    </>
  )
}

export default FW21

export const query = graphql`
    query MotoQuery {
        allProductStyle(filter: {handle: {in: [
            "buckle-up-nappa-boots",
            "perfecto-vitello-boots",
            "fuel-vitello-boots",
        ]}}) {
            nodes {
                ...PLPStyleFragment
            }
        }
    }
`
